import { Component, Vue } from "vue-property-decorator";
import { getApplicationDetails } from "@/providers/apis/getApplictionDetails";
import { utils } from "@/utils/utils";
import { ReviewerpeerPreviewInterface } from "@/models/reviewer-peers-lists/reviewer-peers-lists.interface";
import moment from "moment";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";
import EmailModalPopupComponent from "../email-modal-popup/email-modal-popup.component.vue";
import RevokeCertificatePopupComponent from "../revoke-certificate-popup/revoke-certificate-popup.component.vue";
import { PEER_FLAG_APPLICANT_QUERY } from "@/graphql/peer-flag-applicant";
import { gql } from "graphql-tag";
import { flagApplicantApi } from "@/providers/apis/peers-flag-applicant";
@Component({
  name: "reviewer-peers-preview",
  components: {
    AppLoaderComponent,
    PreviewPopupComponent,
    EmailModalPopupComponent,
    RevokeCertificatePopupComponent,
  },
})
export default class ReviewerPeersPreview extends Vue {
  public isLoading = false;
  public errorMessage = "";
  public profileData: any = [];
  public certificationData: any = [];
  public file: any = null;
  public showPreviewPopup = false;
  public tableHeader = [
    { key: "applicationTitle", label: "Application Name" },
    { key: "submittedAt", label: "Submission Date" },
    { key: "progressStatus", label: "Status" },
    { key: "action", label: "Action" },
  ];
  public applicationData = [];
  public imgType = ["jpg", "png", "jpeg", "tiff"];
  public getCertificatesFilesPathUrl = "";
  public previewData: any[] = [];
  public emailData: any = null;
  public flagApplicantGrapqlRevoke: any = {};
  public flagHistoryDataApi: any = {};
  public disablePeerProfileEnable = false;
  public peerApplicationDisable: any;
  public thumnailData = "";
  public S3Path = "";
  public isShowImpersonate: any;
  public created() {
    const id: any = this.$route.path.split("/")[3];
    this.getCertificatesFilesPathUrl =
      appConfigService.getS3CertificatesFilesPathUrl();
    this.getPeerApplicationLists(id);
    this.flagApplicantGrapqlQuery();
    this.flagHistoryApi();
    this.S3Path = appConfigService.getS3FilesPathUrl();
  }

  public getPeerApplicationLists(id: string) {
    this.isLoading = true;
    getApplicationDetails
      .getPeersPreviewApplicationList({ id: id })
      .then((response) => {
        this.thumnailData = response.thumbnail;
        this.peerApplicationDisable = response.isDisabled;
        this.applicationData = response.appplications.sort((a: any, b: any) => {
          return (
            new Date(b.submittedAt).getTime() -
            new Date(a.submittedAt).getTime()
          );
        });
        this.isLoading = false;
        this.profileData = this.applicationData.find(
          (data: ReviewerpeerPreviewInterface) => {
            return data.applicationName === "PART TWO";
          }
        );
        this.certificationData = this.applicationData.filter(
          (data: ReviewerpeerPreviewInterface) => {
            data.certificateFile = JSON.parse(data.certificateFile);
            if (
              data &&
              data.progressStatus === "ACCEPTED" &&
              data.applicationName === "PART TWO"
            ) {
              return data;
            }
          }
        );
        this.applicationData.forEach((data: ReviewerpeerPreviewInterface) => {
          if (data.applicationName === "PART TWO") {
            data.applicationTitle = "Initial Application";
          } else {
            data.applicationTitle = `${data.applicationName.toLowerCase()} Application`;
          }
          data.submittedAt = data.submittedAt
            ? moment(data.submittedAt).format("MM-DD-YYYY")
            : "--";
          data.expiryDate = data.expiryDate
            ? moment(data.expiryDate).format("MM-DD-YYYY")
            : "--";
        });
        this.isShowImpersonate = this.applicationData.filter(
          (application: any) => {
            return (
              application &&
              application.progressStatus === "ACCEPTED" &&
              application.applicationName === "PART TWO"
            );
          }
        );
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public previewForm(row: any) {
    this.$router.push({
      path: `/reviewer-dashboard/peers-preview/${row.item.id}`,
      query: {
        id: this.$route.path.split("/")[3],
        application: row.item.applicationName,
      },
    });
  }
  public previewCertificationForm(content: any) {
    this.file = content.certificateFile;
    if (this.file) {
      const img = new Image();
      this.previewData = [
        {
          width: img.width,
          height: img.height,
          type: this.file.type,
          originalFileName: this.file.originalFileName,
          uploadedFileName: this.file.uploadedFileName,
        },
      ];
      this.showPreviewPopup = true;
    }
  }
  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewData = [];
    }
  }
  public onOpenModal(row: any) {
    this.emailData = row;
    this.$bvModal.show("email-modal");
  }
  public emailSentStatus(event: boolean) {
    if (event) {
      this.$bvModal.hide("email-modal");
    }
  }
  public revokeCertification() {
    this.disablePeerProfileEnable = false;
    this.$bvModal.show("revoke-certificate-modal");
  }
  public RevokeCertificatePopupClose() {
    this.$bvModal.hide("revoke-certificate-modal");
    const id: any = this.$route.path.split("/")[3];
    this.getPeerApplicationLists(id);
    this.flagHistoryApi();
  }
  public flagHistoryApi() {
    const id: any = this.$route.path.split("/")[3];
    flagApplicantApi.peersFlagHistory({ id: id }).then((res) => {
      this.flagHistoryDataApi = res;
    });
  }
  public disablePeerProfile() {
    this.disablePeerProfileEnable = true;
    this.$bvModal.show("revoke-certificate-modal");
  }
  public flagApplicantGrapqlQuery() {
    this.$apollo
      .query({
        query: gql`query ${PEER_FLAG_APPLICANT_QUERY}`,
      })
      .then((result) => {
        this.flagApplicantGrapqlRevoke = result;
      });
  }
  public deleteProfilePic() {
    this.$bvModal.show("profile-pic-delete");
  }
  public cancelProfilePic() {
    this.$bvModal.hide("profile-pic-delete");
  }
  public submitProfilePic() {
    this.isLoading = true;
    const id: any = this.$route.path.split("/")[3];
    getApplicationDetails.getPeersProfilePicDelete({ id: id }).then((res) => {
      if (res.status == 204) {
        this.$bvModal.hide("profile-pic-delete");
        this.getPeerApplicationLists(id);
      }
    });
  }
  public impersonateUser() {
    const id: any = this.$route.params.peerId;
    getApplicationDetails.getImpersonate({ id: id }).then((response) => {
      const accessToken: any = localStorage.getItem("access_token");
      const userDetail: any = localStorage.getItem("user_details");
      localStorage.setItem("admin_access_token", accessToken);
      localStorage.setItem("admin_user_details", userDetail);
      localStorage.setItem("access_token", response.access_token);
      localStorage.setItem("user_details", JSON.stringify(response));
      localStorage.setItem("profileData", userDetail);
      this.$router.push({
        path: `/user-dashboard/home`,
        query: {
          id: id,
          type: "admin",
        },
      });
      localStorage.setItem("impersonateId", id);
      window.location.reload();
    });
  }
}
